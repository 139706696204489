import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import '../../assets/styles/base/standard-page.scss';
import '../../assets/styles/pages/accident-management.scss';
import Layout from '../../components/layout';
import SubNav from '../../components/sub-nav';
import SEO from '../../components/seo';
import ServicesImage from '../../images/crash-management/services-1.jpg'
import AccidentSubNav from "../../components/accident-sub-nav";

const AccidentManagementServices = () => {
  const data = useStaticQuery(graphql`
    query {
      general {
        country_code
      }
    }
  `);
  if (data.general.country_code === 'nz') {
    return (
      <Layout wrapperClass="standard-page" showSiteIndex={true} hideHeaderFooter={true}>
        <SEO title="DingGo | About Accident Services" />
        <AccidentSubNav/>
        <div className="hero-image">
          <div className="container">
            <img src={ServicesImage}/>
          </div>
        </div>
        <div className="main-wrapper">
          <section className="py-7">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h1>Services</h1>

                  <div className="entry-content" itemProp="mainContentOfPage">
                    <p>Crash Management serves a wide range of customers throughout New Zealand. This includes
                      individual
                      private motorists and self-employed drivers, SMEs, blue-chip corporates, government and local
                      Council fleets. It takes an intelligent and adaptable approach to understand each of these very
                      different demographics and what’s important to them, and to be able to deliver a service that is
                      tailored to each client’s needs.</p>
                    <p>We also serve a wide range of business partners including the car leasing sector, car
                      manufacturer
                      warranty programmes, and selected insurance brokers. We work closely with our partners to develop
                      programmes that suit them and their customers, including co-branding and white-branding.</p>
                    <p>Crash Management can deliver effective services that get drivers back on the road fast. We also
                      have the capability and resources to provide enhanced and fully customised products that deliver
                      value to complex fleet environments. This includes management reporting tailored to the needs of
                      companies of all sizes.</p>

                    <h3 className="wp-block-heading">Service Features &amp; Benefits</h3>

                    <p>A mobile work-force is essential to the economy and the well-being of all New Zealanders. Whether
                      it’s commuting to work, keeping the services sector running or distributing freight around the
                      country, the convenience and safety of driving is something all kiwis take for granted. Don’t let
                      a
                      traffic accident ruin your day or your business. You can depend on Crash Management for expert
                      independent advice, instant assistance and an ‘extreme customer-service’ experience:</p>

                    <ul>
                      <li>24/7 nationwide toll-free service – maximum caller response time 60 seconds</li>
                      <li>Emergency services co-ordination: Police, Ambulance, Fire</li>
                      <li>Salvage &amp; towing co-ordination (cost of towing is billed direct to your insurer)</li>
                      <li>Pro-active timely communications – coordinating all parties including customers, insurance
                        companies, assessors, repairers, and our rental car provider
                      </li>
                      <li>Free courtesy cars in most locations</li>
                      <li>Free rental cars, SUVs, utes &amp; vans for all not-at-fault drivers – <em>subject to the
                        third
                        party’s insurer accepting liability for the accident</em>.
                      </li>
                      <li>Co-ordination of quality damage repairs to manufacturers’ specifications, to ensure the
                        on-going
                        safety of the vehicle
                      </li>
                      <li>Daily progress tracking and pro-active status updates to customers</li>
                      <li>Customer log-in access to specialised accident management cloud-based portal for full
                        transparency &amp; permanent record keeping
                      </li>
                      <li>Windscreen/glass claim co-ordination and on-site replacements (workplace or home)</li>
                      <li>All insurance broker &amp; insurance company clients served, except Protecta Insurance</li>
                    </ul>

                    <h3 className="wp-block-heading">Want to learn more about our comprehensive fleet incident
                      response &amp; risk management solutions?</h3>

                    <p>Good decision!&nbsp; Crash Management will save your business and your fleet time, money,
                      disruption and help reduce accident rates. For a no obligation information pack or a confidential
                      discussion of your fleet environment and needs, contact <a
                        href="mailto:sales@crashmanagement.co.nz">sales@crashmanagement.co.nz</a> now for a
                      same-day response.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
  if (data.general.country_code === 'au') {
    return (
      <Layout wrapperClass="standard-page" showSiteIndex={true} hideHeaderFooter={true}>
        <SEO title="DingGo | About Accident Services" />
        <AccidentSubNav/>
        <div className="hero-image">
          <div className="container">
            <img src={ServicesImage}/>
          </div>
        </div>
        <div className="main-wrapper">
          <section className="py-7">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h1>Services</h1>
                  <div className="entry-content" itemProp="mainContentOfPage">
                    <div className="row align-items-stretch orange-card-row">
                      <div className="col-4">
                        <div className="orange-card">
                          <div className="orange-card-heading">Incident</div>
                          <div className="orange-card-text">
                            <ul>
                              <li>Make Safe</li>
                              <li>FNOL</li>
                              <li>Lodge Claim</li>
                              <li>Tow</li>
                              <li>Repairer Allocation</li>
                              <li>Onsite quote booking</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="orange-card">
                          <div className="orange-card-heading">Insurance Claim Management</div>
                          <div className="orange-card-text">
                            <ul>
                              <li>Insurance claims lodgement</li>
                              <li>Insurance claims management</li>
                              <li>Determination of over/under excess</li>
                              <li>Allocation to insurers preferred repairers</li>
                              <li>Payment of insurance excess direct to repairers</li>
                              <li>Comprehensive and consolidated reporting so both sub excess and insurance repair details</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="orange-card">
                          <div className="orange-card-heading">Quote</div>
                          <div className="orange-card-text">
                            <ul>
                              <li>Ensure vehicle reaches repairer</li>
                              <li>Repairer quotes</li>
                              <li>All quote details sent to insurer</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-stretch orange-card-row">
                      <div className="col-4">
                        <div className="orange-card">
                          <div className="orange-card-heading">Assess and Authorise</div>
                          <div className="orange-card-text">
                            <ul>
                              <li>Assessor assesses (if required)</li>
                              <li>Repair authorisation provided</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="orange-card">
                          <div className="orange-card-heading">Repair</div>
                          <div className="orange-card-text">
                            <ul>
                              <li>Arrange repair date</li>
                              <li>Arrange hire car</li>
                              <li>Ensure vehicle reaches repairer</li>
                              <li>Repairs</li>
                              <li>Manage any supplementaries</li>
                              <li>Assess any supplementaries</li>
                              <li>Manage any delays</li>
                              <li>Manage pickup</li>
                              <li>Manage hire car return and ensuring it doesn’t exceed limit</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="orange-card">
                          <div className="orange-card-heading">Close and Report</div>
                          <div className="orange-card-text">
                            <ul>
                              <li>Review of experience</li>
                              <li>Payment of Repairer</li>
                              <li>Payment of hire car</li>
                              <li>Payment of tow</li>
                              <li>Reporting</li>
                              <li>Third party claim management</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
};

export default AccidentManagementServices;